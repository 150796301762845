import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import FirebaseApp from "./../../config/firebase";
import Logo from './../../images/logo-white.png';
import { navigate } from 'gatsby'
import './navbar.css'
// import { navigate } from 'gatsby'; //import navigate from gatsby
let database = FirebaseApp.database().ref("/");


const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(1),
    },
    title: {

    },
}));

export default function MenuAppBar(props) {
    const classes = useStyles();
    const [auth, setAuth] = React.useState(true);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [user, setuser] = useState({});

    const open = Boolean(anchorEl);

    const handleChange = (event) => {
        setAuth(event.target.checked);
    };

    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    const logOut = () => {
        FirebaseApp.auth().signOut().then(() => {
            navigate("/")
        }).catch((error) => {
        });
    }

    useEffect(() => {
        FirebaseApp.auth().onAuthStateChanged(user => {
            if (user && user.emailVerified) {
                database.child("Registration/" + user.uid).once('value', res => {
                    let data = res.val()
                    setuser(data)
                })
            }

        })
    }, [])
    return (
        <div className={classes.root}>
            <AppBar position="static" className="_appBar">
                <Toolbar className="_toolBar">
                    <Typography variant="h6" className={classes.title} onClick={() => navigate("/courses")}>
                        <img src={Logo} className="_Logo" />
                    </Typography>
                    {auth && (
                        <div className="_profileDiv">
                            <IconButton
                                aria-label="account of current user"
                                aria-controls="menu-appbar"
                                aria-haspopup="true"
                                onClick={handleMenu}
                                color="inherit"
                                className="_profileSec"
                            >
                                <span style={{ fontSize: 14, marginRight: "-30px" }}>
                                    {user.name}
                                </span>
                                <img src={user.url} className="_profileImage" />
                            </IconButton>
                            <Menu
                                id="menu-appbar"
                                anchorEl={anchorEl}
                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                keepMounted
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                open={open}
                                onClose={handleClose}
                            >
                         
                                <MenuItem onClick={logOut} className="_menue_links">Logout</MenuItem>
                            </Menu>
                        </div>
                    )}
                </Toolbar>
            </AppBar>
        </div>
    );
}
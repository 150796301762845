
import React from 'react';
import MenuAppBar from './../../Components/Navbar/navbar';
import FirebaseApp from "./../../config/firebase";
import { Container, Row, Col, Form } from "react-bootstrap";
import { FcAlarmClock } from "react-icons/fc";
import { BsFillBellFill } from "react-icons/bs";
import './index.css'
let database = FirebaseApp.database().ref("/");
var moment = require('moment-timezone');
export default class Notifications extends React.Component {
    constructor() {
        super();
        this.state = {
            suspended: "release",
            todayDate: "",
            bgColor: "black",
            notificationData: []
        }
    }
    componentDidMount = () => {
        let { notificationData } = this.state
        var today = new Date();
        var dd = today.getDate();
        var mm = today.getMonth() + 1;
        var yyyy = today.getFullYear();
        this.setState({ todayDate: dd + "/" + mm + "/" + yyyy })
        var currentDate = moment().tz("Pakistan/Islamabad").format("L")
        FirebaseApp.auth().onAuthStateChanged(user => {
            if (user && user.emailVerified) {
                database.child("Notifications/").on("child_added", res => {
                    var suspendedStudent = res.val();
                    if (currentDate === suspendedStudent.startedAt) {
                        suspendedStudent.bgColor = "#a7bed6"
                        suspendedStudent.notificationType = "reminder"
                    }
                    notificationData.push(suspendedStudent)
                    this.setState({ notificationData: notificationData })
                })
            }
        })
    }
    render() {
        let { notificationData } = this.state;
        return (
            <div >
                <MenuAppBar />
                <div style={{ textAlign: "center", marginTop: "20px" }}>Notifications</div>
                <Container fluid>
                    <Row className="justify-content-center">
                        <Col lg="7" xs="12">
                            {
                                notificationData && notificationData.reverse().map((v, i) => {
                                    return (
                                        <div className="_notificationdDiv" style={{ backgroundColor: v.bgColor }}>
                                            {
                                                v.notificationType === "reminder" ? <FcAlarmClock size="20" />
                                                    :
                                                    <BsFillBellFill size="20" color="#dc3545" />
                                            }
                                            <div className="_notificationTxt">
                                                <div>{v.message}</div>
                                                <div>{v.startedAt}</div>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </Col>
                    </Row>
                </Container>
            </div>
        )
    }
}